* {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'DrunkText';
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --primary-red: rgba(251, 11, 16, 1);
  --secondary-red: rgba(237, 28, 36, 1);
  --light-gray: rgb(197, 197, 197);
  --lightMistGray: rgba(237, 238, 238, 0.87);
  --dimGray: rgba(170, 170, 170, 1);
  --darkSlateGray: rgba(41, 45, 50, 1);
  --cloud-mist: rgba(242, 243, 244, 1);
  --white-smoke: rgba(240, 240, 240, 1);
}

/* FONTS */
@font-face {
  font-family: 'DrunkText';
  src: url('../src/assets/fonts/DrukText-Medium-Cy-Gr-App.ttf');
}

@font-face {
  font-family: 'DrunkTextWide';
  src: url('../src/assets/fonts/DrukWide-Medium-Cy-Gr-App.ttf');
}
